import React, {  useState, useEffect } from 'react';
import cmktokenjson from "contracts/CMKToken.json";
import vestingtokenjson from "contracts/VestingSchedule.json";
import { Web3ReactProvider, useWeb3React, } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { useEagerConnect, useInactiveListener, useContract, useContractCallData } from './hooks'
import { utils, BigNumber } from 'ethers';

import { Spinner } from './Spinner'
import { injected } from './connectors'
import './App.css';

const VESTING_TREASURY_ADDRESS = "0x654958393B7E54f1e2e51f736a14b9d26D00Eb1e";

const ONE_MONTH_SEC = 2592000
const SIX_MONTHS_SEC = 6 * ONE_MONTH_SEC;
const TWO_YEARS_SEC = 24 * ONE_MONTH_SEC;
const THREE_YEARS_SEC = 36 * ONE_MONTH_SEC;

// // MAINNET
const PRESEED_VESTING_ADDRESS = "0xCbF507C87f19B58fB719B65697Fb7fA84D682aA9";
const PRESALE_ONE_VESTING_ADDRESS = "0xCA9bb8A10B2C0FB16A18eDae105456bf7e91B041";
const PRESALE_TWO_VESTING_ADDRESS = "0x70371C6D23A26Df7Bf0654C47D69ddE9000013E7";
const KOL_VESTING_ADDRESS = "0x0f8d3D79f5Fb9EDFceFF399F056c996eb9b89C67";
const TEAM_VESTING_ADDRESS = "0xC2560D7D2cF12f921193874cc8dfBC4bb162b7cb";
const FOUNDER_VESTING_ADDRESS = "0xdb9DCecbA3f21e2aa53897a05A92F89209731b68";
const ADVISOR_VESTING_ADDRESS = "0x5CE367c907a119afa25f4DBEe4f5B4705C802Df5";
const SPA_VESTING_ADDRESS = "0x46d812De7EF3cA2E3c1D8EfFb496F070b2202DFF";

// // KOVAN
const PRESEED_VESTING_ADDRESS_KOVAN = "0x9905c72D7c92446B6a2CA3502fEF2202aDD6A293";
const PRESALE_ONE_VESTING_ADDRESS_KOVAN = "0x2B2bCF62Ea174870FDd8eEC29a25ED034180D470";
const PRESALE_TWO_VESTING_ADDRESS_KOVAN = "0x9e75a8D4BF88A2804bf4dB4E8eF2c6A53464E7E8";
const KOL_VESTING_ADDRESS_KOVAN = "0x5Ef9330255bb9aff1640A602D6AeEfc953Fe55a7";
const TEAM_VESTING_ADDRESS_KOVAN = "0x9905c72D7c92446B6a2CA3502fEF2202aDD6A293";
const FOUNDER_VESTING_ADDRESS_KOVAN = "0x0a6f46a9485ba8bB00DDc1903c68672c84CF8965";
const ADVISOR_VESTING_ADDRESS_KOVAN = "0x9905c72D7c92446B6a2CA3502fEF2202aDD6A293";
const SPA_VESTING_ADDRESS_KOVAN = "0x9905c72D7c92446B6a2CA3502fEF2202aDD6A293";

const VS_CONFIG = [
    {
      name: "Pre-Seed",
      1:PRESEED_VESTING_ADDRESS,
      "42":PRESEED_VESTING_ADDRESS_KOVAN,
      undefined:PRESEED_VESTING_ADDRESS_KOVAN,
      price: 0.1,
      cliff: 0,
      duration: TWO_YEARS_SEC
    },
    {
      name: "SPA",
      "1": SPA_VESTING_ADDRESS,
      "42":SPA_VESTING_ADDRESS_KOVAN,
      undefined:SPA_VESTING_ADDRESS_KOVAN,
      price: 0.1,
      cliff: 0,
      duration: TWO_YEARS_SEC
    },
    {
      name: "Pre-Sale 1",
      "1": PRESALE_ONE_VESTING_ADDRESS,
      "42": PRESALE_ONE_VESTING_ADDRESS_KOVAN,
      undefined:PRESALE_ONE_VESTING_ADDRESS_KOVAN,
      price: 0.35,
      cliff: 0,
      duration: TWO_YEARS_SEC
    },
    {
      name: "Pre-Sale 2",
      "1": PRESALE_TWO_VESTING_ADDRESS,
      "42": PRESALE_TWO_VESTING_ADDRESS_KOVAN,
      undefined:PRESALE_TWO_VESTING_ADDRESS_KOVAN,
      price: 0.35,
      cliff: 0,
      duration: TWO_YEARS_SEC
    },
    {
      name: "KOLs",
      "1": KOL_VESTING_ADDRESS,
      "42": KOL_VESTING_ADDRESS_KOVAN,
      undefined:KOL_VESTING_ADDRESS_KOVAN,
      price: 0.35,
      cliff: 0,
      duration: TWO_YEARS_SEC
    },
    {
      name: "Team",
      "1": TEAM_VESTING_ADDRESS,
      "42": TEAM_VESTING_ADDRESS_KOVAN,
      undefined:TEAM_VESTING_ADDRESS_KOVAN,
      price: 0,
      cliff: SIX_MONTHS_SEC,
      duration: THREE_YEARS_SEC
    },
    {
      name: "Founders",
      "1": FOUNDER_VESTING_ADDRESS,
      "42": FOUNDER_VESTING_ADDRESS_KOVAN,
      undefined:FOUNDER_VESTING_ADDRESS_KOVAN,
      price: 0,
      cliff: SIX_MONTHS_SEC,
      duration: THREE_YEARS_SEC
    },
    {
      name: "Advisors",
      "1": ADVISOR_VESTING_ADDRESS,
      "42": ADVISOR_VESTING_ADDRESS_KOVAN,
      undefined:ADVISOR_VESTING_ADDRESS_KOVAN,
      price: 0,
      cliff: SIX_MONTHS_SEC,
      duration: THREE_YEARS_SEC
    }
  ]



function weiToNum(num) {
  return (num / (10 ** 18)).toFixed(3)
}


function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 5000
  return library
}
const weiIfy = (number) => {
  return number.toString() + "000000000000000000"
}


function ConnectChain(props) {
  const context = useWeb3React()
  const { connector, chainId, activate, deactivate, error } = context

  const [activatingConnector, setActivatingConnector] = React.useState()
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined)
    }
  }, [activatingConnector, connector])

  const activating = injected === activatingConnector
  const connected = injected === connector
  const disabled = !props.triedEager || !!activatingConnector || !!error

  useInactiveListener(!props.triedEager || !!activatingConnector)

  let isDisconnect = !error && chainId
  const buttonText = isDisconnect ? 'Disconnect' : (activating ? 'Connectting' : 'Connect')

  return (
    <button
      style={{
        borderColor: activating ? 'orange' : connected ? 'green' : 'unset',
        cursor: disabled ? 'unset' : 'pointer',
        position: 'relative',
      }}
      className="ConnectButton"
      disabled={disabled}
      onClick={() => {
        if (!isDisconnect) {
          setActivatingConnector(injected)
          activate(injected)
        } else {
          deactivate()
        }
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          color: 'black',
          margin: '0 0 0 1rem'
        }}
      >
        {activating && <Spinner color={'red'} style={{ height: '50%', marginLeft: '-1rem' }} />}
      </div>
      { buttonText}
    </button>
  )
}

function VestingScheduleSelectBlock(props) {
  const { account, chainId } = useWeb3React();
  return (
    <div className={`vs_button_group ${props.selected ? 'selected' : ''}`} >
      <p>
        <button onClick={props.click}> {VS_CONFIG[props.index].name} </button><br />
  Balance: {weiToNum(props.balance)} <br />
  Allocated: {weiToNum(props.allocation)}<br />
  Claimed: {weiToNum(props.claimed)}
      </p>
    </div>

  )
}

function App() {
  const [vestingRecipient, setRecipient] = useState(0);
  const [cliff, setCliff] = useState(0);
  const [duration, setDuration] = useState(TWO_YEARS_SEC);
  const [amount, setAmount] = useState(0);
  const [totalClaimableAmount, setTotalClaimableAmount] = useState(0);

  const [fundable, setFundable] = useState(false);

  const { account, chainId } = useWeb3React();
  const triedEager = useEagerConnect();


  const cmkContract = useContract(cmktokenjson);
  const cmkBalance = useContractCallData(cmkContract, 'balanceOf', [account]);

  const vestingContracts = [];
  vestingContracts[0] = useContract(vestingtokenjson, VS_CONFIG[0][chainId]);
  vestingContracts[1] = useContract(vestingtokenjson, VS_CONFIG[1][chainId]);
  vestingContracts[2] = useContract(vestingtokenjson, VS_CONFIG[2][chainId]);
  vestingContracts[3] = useContract(vestingtokenjson, VS_CONFIG[3][chainId]);
  vestingContracts[4] = useContract(vestingtokenjson, VS_CONFIG[4][chainId]);
  vestingContracts[5] = useContract(vestingtokenjson, VS_CONFIG[5][chainId]);
  vestingContracts[6] = useContract(vestingtokenjson, VS_CONFIG[6][chainId]);
  vestingContracts[7] = useContract(vestingtokenjson, VS_CONFIG[7][chainId]);

  const claimableAmounts = [];
  claimableAmounts[0] = useContractCallData(vestingContracts[0], 'getClaimableAmount', [account]);
  claimableAmounts[1] = useContractCallData(vestingContracts[1], 'getClaimableAmount', [account]);
  claimableAmounts[2] = useContractCallData(vestingContracts[2], 'getClaimableAmount', [account]);
  claimableAmounts[3] = useContractCallData(vestingContracts[3], 'getClaimableAmount', [account]);
  claimableAmounts[4] = useContractCallData(vestingContracts[4], 'getClaimableAmount', [account]);
  claimableAmounts[5] = useContractCallData(vestingContracts[5], 'getClaimableAmount', [account]);
  claimableAmounts[6] = useContractCallData(vestingContracts[6], 'getClaimableAmount', [account]);
  claimableAmounts[7] = useContractCallData(vestingContracts[7], 'getClaimableAmount', [account]);

  const balancesOfContracts = [];
  balancesOfContracts[0] = useContractCallData(cmkContract, 'balanceOf', [VS_CONFIG[0][chainId]]);
  balancesOfContracts[1] = useContractCallData(cmkContract, 'balanceOf', [VS_CONFIG[1][chainId]]);
  balancesOfContracts[2] = useContractCallData(cmkContract, 'balanceOf', [VS_CONFIG[2][chainId]]);
  balancesOfContracts[3] = useContractCallData(cmkContract, 'balanceOf', [VS_CONFIG[3][chainId]]);
  balancesOfContracts[4] = useContractCallData(cmkContract, 'balanceOf', [VS_CONFIG[4][chainId]]);
  balancesOfContracts[5] = useContractCallData(cmkContract, 'balanceOf', [VS_CONFIG[5][chainId]]);
  balancesOfContracts[6] = useContractCallData(cmkContract, 'balanceOf', [VS_CONFIG[6][chainId]]);
  balancesOfContracts[7] = useContractCallData(cmkContract, 'balanceOf', [VS_CONFIG[7][chainId]]);

  const totalAllocation = [];
  totalAllocation[0] = useContractCallData(vestingContracts[0], 'getTotalAllocation', []);
  totalAllocation[1] = useContractCallData(vestingContracts[1], 'getTotalAllocation', []);
  totalAllocation[2] = useContractCallData(vestingContracts[2], 'getTotalAllocation', []);
  totalAllocation[3] = useContractCallData(vestingContracts[3], 'getTotalAllocation', []);
  totalAllocation[4] = useContractCallData(vestingContracts[4], 'getTotalAllocation', []);
  totalAllocation[5] = useContractCallData(vestingContracts[5], 'getTotalAllocation', []);
  totalAllocation[6] = useContractCallData(vestingContracts[6], 'getTotalAllocation', []);
  totalAllocation[7] = useContractCallData(vestingContracts[7], 'getTotalAllocation', []);

  const totalClaimedAllocation = [];
  totalClaimedAllocation[0] = useContractCallData(vestingContracts[0], 'getTotalClaimedAllocation', []);
  totalClaimedAllocation[1] = useContractCallData(vestingContracts[1], 'getTotalClaimedAllocation', []);
  totalClaimedAllocation[2] = useContractCallData(vestingContracts[2], 'getTotalClaimedAllocation', []);
  totalClaimedAllocation[3] = useContractCallData(vestingContracts[3], 'getTotalClaimedAllocation', []);
  totalClaimedAllocation[4] = useContractCallData(vestingContracts[4], 'getTotalClaimedAllocation', []);
  totalClaimedAllocation[5] = useContractCallData(vestingContracts[5], 'getTotalClaimedAllocation', []);
  totalClaimedAllocation[6] = useContractCallData(vestingContracts[6], 'getTotalClaimedAllocation', []);
  totalClaimedAllocation[7] = useContractCallData(vestingContracts[7], 'getTotalClaimedAllocation', []);

  const [vsIndex, setIndex] = useState(0);

  const transferAmount = async (amount) => {
    await cmkContract['transfer'](VS_CONFIG[vsIndex][chainId], weiIfy(amount));
  }

  const addVestingSchedule = async (recipient, amount, duration, cliff) => {
    await vestingContracts[vsIndex]['addVestingSchedule'](recipient, weiIfy(amount), duration, cliff);
  }

  const claimOne = async (address) => {
    for (var v of Object.keys(claimableAmounts)) {
      if (v == address) {
        await vestingContracts[v]['claim']()
      }
    }
  }

  useEffect(() => {
    let ca = 0;
    for (var v of Object.keys(claimableAmounts)) {
      ca += claimableAmounts[v] ? parseFloat(BigNumber.from(claimableAmounts[v])) : 0
    }
    setTotalClaimableAmount((ca / (10 ** 18)).toFixed(3))
  }, [claimableAmounts]);

  useEffect(() => {
    setTotalClaimableAmount("Loading...");
  }, [account]);

  useEffect(() => {
    setFundable(
      weiToNum(balancesOfContracts[vsIndex]) >=
      weiToNum(totalAllocation[vsIndex]) -
      weiToNum(totalClaimedAllocation[vsIndex]) +
      parseFloat(amount));
  }, [vsIndex, amount]);

  useEffect(() => {
    setCliff(VS_CONFIG[vsIndex].cliff);
    setDuration(VS_CONFIG[vsIndex].duration);
  }, [vsIndex, chainId]);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Claim CMK</h1>
        <ConnectChain triedEager={triedEager} />

      </header>
      <br />
      <p> CMK Balance: {cmkBalance ? parseFloat(utils.formatEther(BigNumber.from(cmkBalance))).toFixed(3) : "Loading"} </p>
      <p> Claimable Amount: {totalClaimableAmount} </p>
      {Object.keys(claimableAmounts).map((vsa) => {
        if (claimableAmounts[vsa] == null) {
          return null
        }
        return (
          <div>
            <button onClick={() => { claimOne(vsa) }}>Claim CMK: {(claimableAmounts[vsa] / (10 ** 18)).toFixed(3)}</button>
          </div>
        )
      })}
      <br></br>

      { account == VESTING_TREASURY_ADDRESS ? (
        <div>

          <h2> Vesting Schedule Creation</h2>


          {VS_CONFIG.map((vs_config, idx) => {
            return (
              <VestingScheduleSelectBlock
                index={idx}
                selected={vsIndex == idx}
                click={() => { setIndex(idx) }}
                balance={balancesOfContracts[idx]}
                allocation={totalAllocation[idx]}
                claimed={totalClaimedAllocation[idx]}
              />
            )
          })}


          <br></br>
          <div className="vesting_field ">
            Vesting Schedule Recipient: <input type="text" onChange={event => setRecipient(event.target.value)}></input>
          </div>
          <div className="vesting_field ">
            Vesting Schedule amount: <input type="text" onChange={event => setAmount(event.target.value)}></input>
          </div>
          <div className="vesting_field optional">
            Custom Cliff (s): <input type="text" onChange={event => setCliff(event.target.value)}></input>
          </div>
          <div className="vesting_field optional">
            Custom Schedule Duration (s): <input type="text" onChange={event => setDuration(event.target.value)}></input>
          </div>
          <br />


          <h3>Summary</h3>
          <div className={`summary_row ${!utils.isAddress(VS_CONFIG[vsIndex][chainId]) ? "summary_error" : ""}`}><div className="summary_label">Contract</div><div className="summary_value">
            {VS_CONFIG[vsIndex].name}
          </div></div>
          <div className={`summary_row ${!utils.isAddress(vestingRecipient) ? "summary_error" : ""}`}><div className="summary_label">Recipient:</div><div className="summary_value">
            {vestingRecipient}
          </div></div>
          <div className={`summary_row ${!amount ? "summary_error" : ""}`}><div className="summary_label">Amount:</div><div className="summary_value">
            {amount}
          </div></div>
          <div className="summary_row"><div className="summary_label">Value:</div><div className="summary_value">
            ${(amount * VS_CONFIG[vsIndex].price).toFixed(0)}
          </div></div>
          <div className="summary_row"><div className="summary_label">Cliff (sec):</div><div className="summary_value">
            {(cliff == 0) ? "No Cliff" : ""} {(cliff == 15552000) ? "6 months" : ""} - {cliff} seconds
      </div></div>
          <div className="summary_row"><div className="summary_label">Duration (sec): </div><div className="summary_value">
            {(duration == TWO_YEARS_SEC) ? "Two Years" : ""} {(duration == THREE_YEARS_SEC) ? "Three Years" : ""} - {duration} seconds
      </div></div>
          <div className="summary_row"><div className="summary_label">Executable: </div><div className="summary_value">
            <tt>{VS_CONFIG[vsIndex][chainId]}.addVestingSchedule({vestingRecipient}, {weiIfy(amount)}, {duration}, {cliff})</tt>
          </div></div>

          <div className="summary_row"><div className="summary_label">Transfer: {chainId == 1 ? "WARNING!!! MAINNET" : ""}</div><div className="summary_value">
            <button onClick={() => { transferAmount(amount) }} > Transfer {amount}  </button>
          </div></div>
          <div className="summary_row"><div className="summary_label">Add Vesting Schedule: {chainId == 1 ? "WARNING!!! MAINNET" : ""}</div><div className="summary_value">
            <button onClick={() => { addVestingSchedule(vestingRecipient, amount, duration, cliff) }}  disabled={!fundable}> Add Vesting {chainId == 1 ? "WARNING!!! MAINNET" : ""}</button>
          </div></div>

          </div>

      ) : (null)}
    </div>
  );
}

export default function () {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <App />
    </Web3ReactProvider>
  )
}
